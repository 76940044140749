import ModalSearch from "./ModalSearch";
import ModalInfo from "./ModalInfo";
import ModalShare from "./ModalShare";
import ModalDownloadImage from "./ModalDownloadImage";
import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

/**
 *
 * Returns the button that displays the search modal
 *
 * @returns
  {
    JSX.Element
  }
 * @constructor
 */
function ButtonSearch({handleShow, handleClose}) {

  const title = 'Search the Utrecht Psalter';
  const body = <ModalSearch handleClose={handleClose}/>;

  return (
    <OverlayTrigger placement={'bottom'}
                    overlay={<Tooltip>Search the Psalter</Tooltip>}>
      <button onClick={() => handleShow(title, body, 'lg')}><i className="fa-search fa-2x"/></button>
    </OverlayTrigger>
  );
}


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

function ButtonInfo({handleShow}) {

  const title = 'The Utrecht Psalter Annotated Edition';
  const body = <ModalInfo/>

  return (
    <OverlayTrigger placement={'bottom'}
                    overlay={<Tooltip>Information and help</Tooltip>}>
      <button onClick={() => handleShow(title, body, 'xl')}><i className="fa-info-circle fa-2x"/></button>
    </OverlayTrigger>
  );
}


/**
 *
 * @param handleShow
 * @param shareUrl
 * @returns {JSX.Element}
 * @constructor
 */
function ButtonShare({handleShow, handleClose}) {

  const title = 'Share this page';
  const body = <ModalShare handleClose={handleClose}/>

  return (
    <OverlayTrigger placement={'bottom'}
                    overlay={<Tooltip>Share this image</Tooltip>}>
      <button onClick={() => handleShow(title, body)}><i className="ubu-share"/></button>
    </OverlayTrigger>
  );
}


/**
 *
 * @param indexInfo
 * @param handleShow
 * @returns {JSX.Element}
 * @constructor
 */
function ButtonDownloadImage({handleShow, handleClose}) {

  const title = 'Download this image';
  const body = (<ModalDownloadImage handleClose={handleClose}/>);

  return (
    <OverlayTrigger placement={'bottom'}
                    overlay={<Tooltip>Download this image</Tooltip>}>
      <button onClick={() => handleShow(title, body)}><i className="fa fa-download fa-2x"/></button>
    </OverlayTrigger>
  );
}

/**
 *
 * @param fullScreenHandle
 * @returns {JSX.Element}
 * @constructor
 */

function ButtonFullScreen({fullScreenHandle}) {

  const iconClass = (fullScreenHandle.active) ? 'fullscreen full' : 'fullscreen';

  function toggle() {
    if (fullScreenHandle.active)
      fullScreenHandle.exit()
    else
      fullScreenHandle.enter()
  }


  return (
    <OverlayTrigger placement={'bottom'}
                    overlay={<Tooltip>Show full screen</Tooltip>}>
      <button onClick={toggle} className={iconClass}><i/></button>
    </OverlayTrigger>
  )

}


export {ButtonSearch, ButtonInfo, ButtonShare, ButtonDownloadImage, ButtonFullScreen};

import {Button, Col, Row, Form} from "react-bootstrap";
import useSearch from "../../hooks/useSearch";
import {REQUEST_STATUS} from "../../helpers/Utils";
import React from "react";
import {isEmpty} from "lodash";
import {Link} from "react-router-dom";



function ResultIcon({type}) {

  let iconClass = (type === 'transcription') ? 'fa-file-alt ' :
    (type === 'annotation') ? 'fa-comment' : '';

  return   <i className={`fa ${iconClass}`}/>
}

/**
 * Display a line of search result with the text highlighted
 *
 * @param line
 * @param searchString
 * @returns {JSX.Element}
 */
function ResultTextLine({text, searchString}) {

  const parts = text.split(new RegExp(`(${searchString})`, 'gi'));

  return (
    <p className="search-result-line">
        {parts.map((part, i) => {
          const spanClass = part.toLowerCase() === searchString.toLowerCase() ? 'highlightedText' : ''
          return (
            <span key={i} className={spanClass}>{part}</span>
          )
        })}
    </p>
  )
}

/**
 * Display the search results
 *
 * @param searchResults
 * @param searchString
 * @param requestStatus
 * @param error
 * @param handleClose
 * @returns {JSX.Element|string}
 */
function SearchResults({searchResults, searchString, requestStatus, error, handleClose}) {

  if (isEmpty(searchString) && isEmpty(searchResults))
    return '';

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return (
      <div className="search-result-searching fa-3x" >
        <i className="fas fa-circle-notch fa-spin" />
        <p>Searching ...</p>
      </div>
    )
  }
  if (requestStatus === REQUEST_STATUS.FAILURE)
    return <div>Error: {error}</div>;

  if (searchString && isEmpty(searchResults))
    return <div className="search-result-noresults"><p>No results for: <b>{searchString}</b></p></div>;


  return (
    <div id="search-results">
      <h3>Results for "{searchString}"</h3>
      {searchResults.map((result, index) => {
        const {leafNum, title, text} = result;
        return (
          <div className={`search-results-list`} key={index}>
            <h4>{title}</h4>
            {text.map((line, index) => {
              return (
                <Link  key={index} to={`/page/${leafNum + 1}`} onClick={handleClose}  >
                  <ResultIcon type={line.type} />
                  <ResultTextLine text={line.text} searchString={searchString}/>
                </Link>
              )
            })}
          </div>
        )
      })}
    </div>
  );
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function ModalSearch({handleClose}) {

  const {
    searchString, setSearchString,
    searchResults,
    error, requestStatus,
  } = useSearch();

  let searchInput = null;

  /**
   * Set the search string and start the search process
   */
  function search() {
    setSearchString(searchInput || searchString);
  }

  /**
   *
   * @param e
   */
  function updateSearchString(e) {
    searchInput = e.target.value;
  }

  return (
    <>
      <Row>
        <Col>
          <Form.Control placeholder="Search text" id="searchText" type="search"
                        onChange={updateSearchString}
          />
        </Col>
        <Col>
          <Button onClick={search} variant="primary" type="submit">
            Search
          </Button>
        </Col>
      </Row>
      <SearchResults
        searchResults={searchResults}
        searchString={searchString}
        requestStatus={requestStatus}
        error={error}
        handleClose={handleClose}
      />
    </>
  )
}



export default ModalSearch;

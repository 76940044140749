import {useEffect, useState} from "react";
import {isEmpty} from "lodash";
import axios from "axios";
import {REQUEST_STATUS, iterable} from "../helpers/Utils";
import Config from "../helpers/Config";


/**
 * Parse result of the search request
 *
 * @param data
 * @param searchString
 * @return {any[]}
 */
const parseSearchResults = function (data, searchString) {

  let searchResults = [];


  /**
   * Extract transcription text from the result record
   *
   * @param record
   * @returns {Array}
   */
  const getResultText = function (record) {

    let results = [];

    const resultLine = function (type, txt) {
      return {type: type, text: txt};
    };


    // First look into the DC.TRANSCRIPTION
    if (record.hasOwnProperty('DC.ANNOTATION')) {

      // Then into the DC.ANNOTATION
      // Annotation rex extracts a string with 90 characters around the searchString
      const annoRex = new RegExp('\\b.{1,90}' + searchString + '.{1,90}\\b', 'ig');
      iterable(record['DC.ANNOTATION'].match(annoRex)).forEach( line => {
        results.push(resultLine('annotation', '...' + line + '...'));
      });

      const transRegex = new RegExp('\\b' + searchString + '\\b', 'ig');

      record['DC.TRANSCRIPTION'].forEach(function (tran) {
        tran['$'].split('\n')
          .forEach(line => {
            if (line.match(transRegex)) {
              results.push(resultLine('transcription', line));
            }
          });
      });

    }

    return results;
  };

  /**
   * Parse record data to get relevant information to show to the user
   *
   * @param record
   */
  const parseRecordData = function (record) {

    const matchedText = getResultText(record);
    // Only add the part of text that contain the searchString
    if(!isEmpty(matchedText)) {
      searchResults.push({
          title: record['DC.TITLE'],
          leafNum: parseInt(record['LEAFNUM']),
          text: matchedText,
        }
      );

    }
  };


  if (parseInt(data['autn:numhits']) === 1) {
    parseRecordData(data['autn:hit']['autn:content']['RECORD']);
  } else {
    data['autn:hit'].forEach(function (hit) {
      parseRecordData(hit['autn:content']['RECORD']);
    });
  }

  return searchResults;
};

/**
 *
 * @returns {{
 *  searchString: string,
 *  setSearchString: (value: (((prevState: string) => string) | string)) => void,
 *  error: unknown,
 *  searchResults: {},
 *  requestStatus: string
 *  }}
 */
function useSearch() {

  const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const [searchString, setSearchString] = useState('');

  const apiUrl = Config('OBJECTS_API');

  useEffect(() => {
    if (!isEmpty(searchString)) {
      setError(null);
      setSearchResults([])
      setRequestStatus(REQUEST_STATUS.LOADING);

      axios.get(`${apiUrl}`, {
          params: {
            mode: 9,
            format: 'json',
            query: '"' + searchString.replace(/\s/g, '+') + '"',
            highlight: 0
          },
        }
      ).then(res => {
        const {data, status} = res;

        if (status !== 200) {
          throw new Error('Request error');
        }
        if (parseInt(data.autnresponse.responsedata['autn:numhits']) > 0) {
          const searchResults = parseSearchResults(data.autnresponse.responsedata, searchString);
          setSearchResults(searchResults);
        }
        setRequestStatus(REQUEST_STATUS.SUCCESS);

      }).catch(err => {
        setRequestStatus(REQUEST_STATUS.FAILURE);
        setError('Search error, try again');
      });

    }
  }, [searchString, apiUrl, ]);


  return {
    searchString, setSearchString,
    searchResults,
    error, requestStatus,
  }
}

export default useSearch;
